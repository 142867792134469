import { FAQPageJsonLd, NextSeo } from 'next-seo'
import { useEffect } from 'react'

import { CMSEventParams } from '@/services/amplitude/event.shared.interfaces'
import { useCMSEvent } from '@/services/amplitude/events/useCMSEvent'
import {
  AmplitudeTestKey,
  CommonEventProperties,
  trackEvent
} from '@/services/amplitude/trackEvent'

import { DatadogRumScript } from '../DatadogRumScript'
import {
  AvailableComponentName,
  DynamicComponent,
  DynamicComponentProps
} from '../DynamicComponent'
import { FaqProps, FaqQuestionProps } from '../Faq/Faq.types'

export interface DynamicPageProps {
  name: string
  seo: {
    title: string
    description: string
    canonical: string
    openGraph?: {
      type: string
      url: string
      title: string
      description: string
      images: {
        mime: string
        path: string
        url: string
        height: number
        width: number
        alternativeText: string
      }[]
      site_name: string
      locale: string
    }
  }
  trackOnPageView?: CMSEventParams[]
  isDatadogRumEnabled: boolean | null
  components: DynamicComponentProps[]
  scrollRestoration?: 'auto' | 'manual'
}

type TrackEventType = {
  description?: string
  section_reference?: string
  cta_reference?: string
  experiment_id?: string
  variant_id?: string
} & CommonEventProperties

export function DynamicPage({
  components,
  seo,
  scrollRestoration,
  trackOnPageView,
  isDatadogRumEnabled
}: DynamicPageProps) {
  const firstEvent = useCMSEvent(trackOnPageView?.[0])
  const secondEvent = useCMSEvent(trackOnPageView?.[1])
  const thirdEvent = useCMSEvent(trackOnPageView?.[2])
  const environment =
    process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' ? 'prod' : 'dev'
  const firstFaqComponentProps = components?.find(
    (component) => component.name === 'contents.faq'
  ) as DynamicComponentProps<FaqProps>

  const faqDataForSEOPurposes = firstFaqComponentProps?.questions?.map(
    (item: FaqQuestionProps) => ({
      questionName: item.question,
      acceptedAnswerText: item.answer
    })
  )

  useEffect(() => {
    if (scrollRestoration) {
      window.history.scrollRestoration = scrollRestoration
    }
  }, [scrollRestoration])

  useEffect(() => {
    if (!trackOnPageView?.length) {
      return
    }

    function track(
      cmsEventData: CMSEventParams,
      {
        rules,
        customEventProperties
      }: typeof firstEvent | typeof secondEvent | typeof thirdEvent
    ) {
      if (!cmsEventData) return

      const compliesWithCMSRule =
        rules[cmsEventData.ruleToTrack as keyof typeof rules]

      if (compliesWithCMSRule()) {
        trackEvent<TrackEventType>(
          cmsEventData.experiment?.key as AmplitudeTestKey,
          cmsEventData.name,
          {
            description: cmsEventData?.description,
            section_reference: cmsEventData?.sectionReference,
            cta_reference: cmsEventData?.ctaReference,
            experiment_id: cmsEventData.experiment?.key,
            ...customEventProperties
          }
        )
      }
    }

    track(trackOnPageView[0], firstEvent)
    track(trackOnPageView[1], secondEvent)
    track(trackOnPageView[2], thirdEvent)
  }, [firstEvent, secondEvent, thirdEvent, trackOnPageView])

  return (
    <>
      {isDatadogRumEnabled && <DatadogRumScript />}

      {seo && (
        <NextSeo
          title={seo.title}
          noindex={environment === 'dev'}
          description={seo.description}
          canonical={seo.canonical}
          openGraph={{
            ...seo.openGraph,
            images: seo.openGraph?.images?.map((image) => ({
              url: image.url,
              width: image.width,
              height: image.height,
              alt: image.alternativeText,
              type: image.mime
            }))
          }}
        />
      )}

      {faqDataForSEOPurposes && (
        <FAQPageJsonLd mainEntity={faqDataForSEOPurposes} />
      )}

      <div className="overflow-x-hidden relative">
        {components?.map(({ name, ...props }, index) => (
          <DynamicComponent
            key={`${index}.${name}`}
            name={name as AvailableComponentName}
            {...props}
          />
        ))}
      </div>
    </>
  )
}
