import { GetStaticProps } from 'next'

import { DynamicPage } from '@/cmsComponents/DynamicPage'
import { DynamicPageProps } from '@/cmsComponents/DynamicPage'
import DefaultLayout from '@/layouts'
import { GetDataByCMS } from '@/services/CMSApi'
import { getPOSDevices, getPlansMachines } from '@/services/machines'
import {
  getMachinePlans,
  getSmartphonePlans,
  getPlanInfoForAvailablePlans,
  getPlanTapton
} from '@/services/plans'
import { PlanFee, PlanMachines } from '@/types'
import { InformationsPlan, ITapTon } from '@/types/plans'
import { InformationsMachine } from '@/types/machines'

export interface DynamicPageGetStaticProps {
  machinePlans: InformationsPlan[]
  smartphonePlans: InformationsPlan[]
  machineDevices: InformationsMachine[]
  planFees: PlanFee[]
  plansMachines: PlanMachines[]
  tapton: ITapTon
  cmsPageData: DynamicPageProps
}

export const getStaticProps: GetStaticProps<
  DynamicPageGetStaticProps
> = async () => {
  const machinePlans = await getMachinePlans()
  const smartphonePlans = await getSmartphonePlans()
  const machineDevices = await getPOSDevices()
  const plansMachines = await getPlansMachines()
  const planFees = await getPlanInfoForAvailablePlans()
  const tapton = await getPlanTapton()
  const cmsPageData = await GetDataByCMS('index')

  return {
    props: {
      machinePlans,
      smartphonePlans,
      machineDevices,
      planFees,
      plansMachines,
      tapton,
      cmsPageData
    }
  }
}

export default function Page({
  machinePlans,
  smartphonePlans,
  machineDevices,
  plansMachines,
  planFees,
  tapton,
  cmsPageData
}: DynamicPageGetStaticProps) {
  return (
    <DefaultLayout
      machinePlans={machinePlans}
      smartphonePlans={smartphonePlans}
      machineDevices={machineDevices}
      plansMachines={plansMachines}
      planFees={planFees}
      tapton={tapton}
    >
      <DynamicPage {...cmsPageData} />
    </DefaultLayout>
  )
}
