import Script from 'next/script'

export function DatadogRumScript() {
  return (
    <Script id="datadog-rum" strategy="afterInteractive">
      {`
      (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
      window.DD_RUM.onReady(function() {
        window.DD_RUM.init({
          applicationId: 'dfa235db-cfce-44a3-9dfc-19e0b0c12c7a',
          clientToken: 'pubad659c4586d9c80fda10acc1896d3e42',
          site: 'https://app.datadoghq.com/',
          service: 'https://www.ton.com.br/',
          env: 'production',
          version: '1.54.0',
          sessionSampleRate: 50,
          sessionReplaySampleRate: 1,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
        window.DD_RUM.setGlobalContext({
          owner: "customer-platforms-tribo-web",
          project: "stone-ton/landing-ton",
          "cost-center": "860290032",
          support: "customer-platforms-tribo-web",
        });
      })
    `}
    </Script>
  )
}
